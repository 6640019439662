import SmoothScroll from 'smooth-scroll';
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import ScrollHint from 'scroll-hint';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import lottieWeb from 'lottie-web';
import AOS from 'aos';

const body = document.querySelector('body');
const menuOpenBtn = document.querySelector('.header__button');
const menuContent = document.querySelector('.header__menu');
const menuLink = document.querySelectorAll('.header__menu__item a');
const menuCloseBtn = document.querySelector('.header__menu__close');
const heroWrapper = document.querySelector('.hero__wrapper');
const aboutSentence = document.querySelector('.about__sentence');
const lottieYoutube = document.querySelector('.about__sentence__image');
const accordionTitle = document.querySelectorAll('.faq__accordion__title');
const accordionArrow = document.querySelectorAll('.faq__accordion__arrow img');
const applyButton = document.querySelector('.apply-button');
const applyButtonClose = document.querySelector('.apply-button__close');
const scrollArea = document.querySelector('.scroll-wrapper');

// AOSアニメーション
aosAnimation();
function aosAnimation() {
  AOS.init({
    duration: 600,
    easing: 'ease-out-cubic',
    once: true,
  });
}
// スムーススクロール
var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

// header メニュー
menuOpen();
function menuOpen() {
  menuOpenBtn.addEventListener('click', function () {
    if (!menuContent.classList.contains('active')) {
      menuContent.classList.add('active');
      body.classList.add('active');

      if (applyButton && applyButton.classList.contains('active')) {
        applyButton.classList.remove('active');
      }
    }
  });
}

menuClose();
function menuClose() {
  menuCloseBtn.addEventListener('click', function () {
    if (menuContent.classList.contains('active')) {
      menuContent.classList.remove('active');
      body.classList.remove('active');
    }
  });

  menuLink.forEach((e) => {
    e.addEventListener('click', function () {
      menuContent.classList.remove('active');
      body.classList.remove('active');
    });
  });
}

// hero スライダー
if (heroWrapper) {
  heroSlider();
}

function heroSlider() {
  const heroSlider01 = '.hero__slider__01';
  const heroSlider02 = '.hero__slider__02';
  const heroSlider03 = '.hero__slider__03';
  const heroSlider04 = '.hero__slider__04';
  const heroSlider05 = '.hero__slider__05';

  const options = {
    type: 'loop',
    arrows: false,
    pagination: false,
    drag: false,
    fixedWidth: '280rem',
    autoScroll: {
      speed: 0.5,
      pauseOnHover: false,
    },
    breakpoints: {
      767: {
        fixedWidth: '169rem',
      },
    },
  };

  const toLeft = {
    ...options,
    direction: 'ltr',
  };

  const toRight = {
    ...options,
    direction: 'rtl',
  };

  const slider01 = new Splide(heroSlider01, toRight);
  const slider02 = new Splide(heroSlider02, toLeft);
  const slider03 = new Splide(heroSlider03, toRight);
  const slider04 = new Splide(heroSlider04, toLeft);
  const slider05 = new Splide(heroSlider05, toRight);
  slider01.mount({ AutoScroll });
  slider02.mount({ AutoScroll });
  slider03.mount({ AutoScroll });
  slider04.mount({ AutoScroll });
  slider05.mount({ AutoScroll });
}

// YouTube画像 アニメーション
function lottieAnimation() {
  lottieWeb.loadAnimation({
    container: lottieYoutube,
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: 'json/youtube-logo.json',
  });
}

// YouTube画像 再生タイミング
if (aboutSentence) {
  playLottie();
}

function playLottie() {
  gsap.registerPlugin(ScrollTrigger);

  gsap.to(aboutSentence, {
    scrollTrigger: {
      trigger: aboutSentence,
      start: '30% bottom',
      once: true,
      // markers: true,
      onEnter: (trigger) => {
        lottieAnimation();
      },
    },
  });

  ScrollTrigger.refresh();
}

// faq アコーディオン
faqAccordion();
function faqAccordion() {
  accordionTitle.forEach((e, i) => {
    e.addEventListener('click', () => {
      const accordionContent = e.nextElementSibling;

      if (!accordionContent.classList.contains('open')) {
        accordionContent.classList.add('open');
        accordionContent.style.height = `${accordionContent.firstElementChild.clientHeight}px`;
        e.style.borderBottom = 'solid 1px #B3B3B4';
        accordionArrow[i].classList.add('active');
      } else {
        accordionContent.classList.remove('open');
        accordionContent.style.height = '0px';
        e.style.borderBottom = null;
        accordionArrow[i].classList.remove('active');
      }
    });
  });
}

// スクロールヒント
scrollHint();
function scrollHint() {
  new ScrollHint('.js-scrollable', {
    i18n: {
      scrollable: 'スクロールできます',
    },
  });
}

// 追従ボタン クリックイベント
if (applyButton) {
  applyButtonClick();
}

function applyButtonClick() {
  applyButtonClose.addEventListener('click', function (event) {
    event.preventDefault();
    
    applyButton.classList.add('hidden');
  });
}

// 追従ボタン スクロールイベント
if (scrollArea) {
  applyButtonScroll();
}

function applyButtonScroll() {
  gsap.registerPlugin(ScrollTrigger);

  gsap.to(applyButton, {
    scrollTrigger: {
      trigger: scrollArea,
      start: 'top center',
      end: 'bottom center',
      // markers: true,
      toggleActions: 'play none none reverse',
      toggleClass: {
        targets: applyButton,
        className: 'active',
      },
    },
  });

  ScrollTrigger.refresh();
}
